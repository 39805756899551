.account__header__bio,
.status__content__text,
.e-content,
.edit-indicator__content,
.reply-indicator__content {
  code {
    background: var(--rich-text-container-color);
    padding: 4px;
    border-radius: 4px;
    color: var(--rich-text-text-color);
    font-size: 0.85em;
  }

  pre {
    background: var(--rich-text-container-color);
    padding: 8px;
    border-radius: 4px;
    color: var(--rich-text-text-color);

    code {
      padding: 0;
      background: transparent;
    }
  }

  pre,
  blockquote {
    margin-bottom: 22px;
    white-space: pre-wrap;
    unicode-bidi: plaintext;

    &:last-child {
      margin-bottom: 0;
    }
  }

  code,
  pre {
    font-family: monospace;
  }

  code {
    margin: 0 8px;
  }

  pre {
    padding: 4px 8px;

    span.ellipsis::after {
      content: '';
    }

    span.invisible {
      display: inline;
      font-size: inherit;
      line-height: inherit;
      width: inherit;
      height: inherit;
      position: static;
    }
  }

  blockquote {
    padding-inline-start: 32px;
    color: var(--rich-text-text-color);
    white-space: normal;
    position: relative;

    &::before {
      display: block;
      content: '';
      width: 24px;
      height: 20px;
      mask-image: url('../images/quote.svg');
      background-color: var(--rich-text-decorations-color);
      position: absolute;
      inset-inline-start: 0;
      top: 0;
    }

    blockquote {
      margin-top: 4px;
      border-inline-start: 3px solid var(--rich-text-decorations-color);
      padding-inline-start: 16px;

      &::before {
        display: none;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  & > ul,
  & > ol {
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  ul,
  ol {
    padding-inline-start: 24px;

    li {
      padding-inline-start: 8px;

      &::marker {
        text-align: end;
      }
    }

    p {
      margin: 0;
    }
  }

  ul {
    list-style-type: '•';

    li::marker {
      text-align: start;
    }
  }

  ol {
    list-style-type: decimal;
  }

  sup {
    vertical-align: super;
  }

  .emojione {
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.status__content__text {
  a.kmy-dangerous-link {
    color: red !important;
  }
}
